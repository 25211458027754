import React, { Component } from 'react';
import './App.css';
import Menu from './components/menu';
import About from './components/about';
import Experience from './components/experience';
import Projects from './components/projects';
import Contact from './components/contact';
import Testimonials from './components/testimonials';
import Footer from './components/footer';


class App extends Component {
  render() {
    return (
      <div>
        <Menu></Menu>
        <About></About>
        <Projects></Projects>
        <Experience></Experience>
        <Testimonials></Testimonials>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    )
  }
}

export default App;
