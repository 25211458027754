import React, { Component } from 'react';

export default class Testimonials extends Component {
  render() {
    return (
      <section className="testimonial py-5" id="testimonials">
        <div className="container section">
          <div className="col-lg-8 text-center mx-auto">
            <h2>Testimonials</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="testimonial-box text-center">
                <h3 className="testimonial-title">Philip Yong <a href="https://www.linkedin.com/in/philipyong/" target="_blank"><img src="images/linkedin.png" className="linkedin-image"></img></a></h3>
                <h4 className="testimonial-subtitle">Software Developer</h4>
                <p className="testimonial-description">Selyn doesn’t settle for what it is. She pushes herself to explore and learn more in her life and her career. She has a passion for tech and continuously yearns to become a better developer each and every day.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="testimonial-box text-center">
                <h3 className="testimonial-title">Husain Abdelrahman <a href="https://www.linkedin.com/in/husain-abdelrahman/" target="_blank"><img src="images/linkedin.png" className="linkedin-image"></img></a></h3>
                <h4 className="testimonial-subtitle">Subleader at TechNext</h4>
                <p className="testimonial-description">Selyn joined the team later than most of the members but surpassed everyone through her ability to learn new things rapidly. She is an outstanding candidate with strong analytical and communication skills.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
