import React, { Component } from 'react';

export default class Experience extends Component {
    render() {
        return (
        <section className="resume py-5 d-lg-flex justify-content-center align-items-center" id="resume">
        <div className="container section">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h2 className="mb-4">Experience</h2>
              <div className="timeline">
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2023</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Full Stack Developer</span></h3>
                    <h6>Midwest Collegiate Computing Conference (MWC3)</h6>
                    <ul>
                      <li>Actively maintained and updated the MWC3 website, utilizing the Django framework.</li>
                      <li>Identified areas for improvement in usability and user friendliness, and developed innovative features accordingly.</li>
                    </ul>
                  </div>
                </div>
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2023</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Course Assistant</span></h3>
                    <h6>WMU Student Success Services</h6>
                    <ul>
                      <li>Delivered personalized mentoring to 5-10 struggling Computer Science students weekly.</li>
                      <li>Guided students in a range of computer science courses, including Data Structures, Algorithms, and Database Management Systems.</li>
                    </ul>
                  </div>
                </div>
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2022</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Learning Assistant</span></h3>
                    <h6>WMU Student Success Services</h6>
                    <ul>
                      <li>Collaborated with Professor Andrew Bowling to design tailored lessons for a group of 38 students, considering their developmental needs.</li>
                      <li>Conducted 3 review session a week, supporting 3-5 students in deepening their understanding of calculus concepts.</li>
                    </ul>
                  </div>
                </div>
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2022</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Business Development Intern</span></h3>
                    <h6><a href="https://eyris.io/" target="_blank">EyRIS Singapore</a></h6>
                    <ul>
                      <li>Conducted extensive market research for an AI startup specializing in automating early detection of retinal diseases.</li>
                      <li>Recognized the critical importance of market understanding in driving the success of technological solutions.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <h2 className="mb-4 mobile-mt-2">Education</h2>
              <div className="timeline">
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2023</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Computer Science</span></h3>
                    <h6>Western Michigan University</h6>
                    <p>Graduated Magna Cum Laude with a CGPA of 3.88 and a minor in Data Science.</p>
                  </div>
                </div>
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2022</span>
                  </div>
                  <div className="timeline-info">
                    <h3><span>Cybersecurity</span></h3>
                    <h6>Codepath</h6>
                    <p>Completed a 3-month course on Cybersecurity online.</p>
                  </div>
                </div>
                <div className="timeline-wrapper">
                  <div className="timeline-yr">
                    <span>2021</span>
                  </div>
                  <div className="timeline-info">
                    <h3>iOS Development</h3>
                    <h6>Codepath</h6>
                    <p>Completed a 4-month course on iOS Development online.</p>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center">
                  <a href="/resume.pdf" className="btn custom-btn custom-btn-bg custom-btn-link" target="_blank"><i className="uil uil-file-alt" /> View Full Resume</a>
              </div>
            </div>

          </div>
        </div>
      </section>
        )
    }
}