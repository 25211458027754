import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer py-5">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">                                
                    <p className="copyright-text text-center">Copyright © 2023 Selyn Ung. All rights reserved</p>
                    </div>
                </div>
                </div>
            </footer>
        )
    }
}