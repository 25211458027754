import React, { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <section className="about full-screen d-lg-flex justify-content-center align-items-center" id="about">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                  <div className="about-text">
                    <h1 className="animated animated-text">
                      <span className="mr-2">Hello there! I'm Selyn, a</span>
                      <div className="animated-info">
                        <span className="animated-item developer">Software Engineer</span>
                        <span className="animated-item developer">Web Developer</span>
                        <span className="animated-item developer">Frontend Developer</span>
                      </div>
                    </h1>
                    <p>I am a fresh graduate looking for full-time opportunities in software development, ping me!</p>
                    <div className="custom-btn-group mt-4">
                      <a href="/resume.pdf" className="btn custom-btn custom-btn-bg custom-btn-link" target="_blank"><i className="uil uil-file-alt" /> View My Resume</a>
                      <a href="#contact" className="btn mr-lg-2 custom-btn">Contact Me</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-12">
                    <img src="images/selyn-profile.jpg" className="img-fluid" alt="selyn profile" />
                </div>
              </div>
            </div>
          </section>
        )
    }
}