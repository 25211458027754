import React, { Component } from 'react';

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen
    }));
  };

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  handleNavItemClick = (event, sectionId) => {
    event.preventDefault();
    this.toggleMenu();
    this.scrollToSection(sectionId);
  };

  render() {
    const { isMenuOpen } = this.state;

    return (
      <nav className={`navbar navbar-expand-sm navbar-light ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img src="images/girl.png" className="logo-image" alt="Logo" />
            Selyn Ung
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? 'active' : ''}`}
            type="button"
            onClick={this.toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  href="#about"
                  className="nav-link"
                  onClick={(event) => this.handleNavItemClick(event, 'about')}
                >
                  <span data-hover="About">About</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#project"
                  className="nav-link"
                  onClick={(event) => this.handleNavItemClick(event, 'project')}
                >
                  <span data-hover="Projects">Projects</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#resume"
                  className="nav-link"
                  onClick={(event) => this.handleNavItemClick(event, 'resume')}
                >
                  <span data-hover="Resume">Resume</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#testimonials"
                  className="nav-link"
                  onClick={(event) => this.handleNavItemClick(event, 'testimonials')}
                >
                  <span data-hover="Testimonials">Testimonials</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                  onClick={(event) => this.handleNavItemClick(event, 'contact')}
                >
                  <span data-hover="Contact">Contact</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
