import React, { Component } from 'react';
import { Widget } from '@typeform/embed-react';

export default class Contact extends Component {
    render () {
        return (
            <section className="contact py-5" id="contact">
            <div className="container section">
            <div className="col-lg-8 text-center mx-auto">
            <h2>Contact Me</h2>
            </div>
            <div className="row">
                <div className="col-lg-5 mr-lg-5 col-12">
                <div className="google-map w-100">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2955.776625937484!2d-93.619759!3d42.030781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ec230c45c2caad%3A0x3c3ee43a94c00875!2sAmes%2C%20IA!5e0!3m2!1sen!2sus!4v1569442069168!5m2!1sen!2sus"
                    width={400}
                    height={300}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                />
                </div>
                <div className="contact-info d-flex justify-content-between align-items-center py-4 px-lg-5">
                    <div className="contact-info-item">
                    <h3 className="mb-3 text-white">Say hello</h3>
                    <p className="footer-text mb-0"><a href="https://www.linkedin.com/in/selynung/" target="_blank">LinkedIn <img src="images/linkedin.png" className="linkedin-image"></img></a></p>
                    <p className="footer-text mb-0">269 779 0272</p>
                    <p><a href="mailto:selynung12@gmail.com">selynung12@gmail.com</a> </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-12">
                <div className="contact-form">
                    <Widget id="Fo4MrC3L" style={{ width: '100%', height: '500px' }} className="my-form" />
                </div>
                </div>
            </div>
            </div>
        </section>
        )
    }
}