import React, { Component } from 'react';

export default class Projects extends Component {
  render() {
    return (
      <section className="project py-5" id="project">
        <div className="container section">
          <div className="col-lg-8 text-center mx-auto">
            <h2>Sneak Peak on Some Things I've Done</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
            <a href="https://github.com/selynung/racktracking" target="_blank">
              <div className="project-box text-center">
                <img className="project-image" src="images/racktrack.gif" alt="Project 1" />
                <h3 className="project-title">Rack Tracking Application</h3>
                <p className="project-description">Developed a Django web application for <a href="http://www.hybels.com/" target="_blank">Bert R. Hybels</a> to optimize supply chain operations at their plant distribution hub. This was a fun and fruitful experience as I previously had zero experience in Django, and I was able to build a fully functional web application in 4 months.</p>
              </div>
            </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            <a href="https://technext-wmu.com/" target="_blank">
              <div className="project-box text-center">
                <img className="project-image" src="images/technext-cropped.jpg" alt="Project 2" />
                <h3 className="project-title">Team TechNext</h3>
                <p className="project-description">Collaborated with a team of students in researching trending AI topics such as NLPs and neural networks. I also gained valuable exposure to the dynamic tech start-up scene in San Francisco as we were able to visit start-ups like <a href="https://primer.ai/" target="_blank">Primer.ai</a> and <a href="https://www.robustintelligence.com/" target="_blank">Robust Intelligence</a>.</p>
              </div>
            </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            <a href="https://github.com/WMUcaleb/Blendr" target="_blank">
              <div className="project-box text-center">
                <img className="project-image" src="images/blendr.gif" alt="Project 3" />
                <h3 className="project-title">Blendr App</h3>
                <p className="project-description">An innovative iOS app designed to assist indecisive eaters in selecting restaurants while fostering a vibrant community of food enthusiasts. This little project marked my debut in the world of mobile apps. It was such a fulfilling experience to witness my intial wireframes and designs come to life.</p>
              </div>
            </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            <a href="https://www.linkedin.com/pulse/covid-19-fuels-ai-platforms-causing-cloud-providers-aarthi-srinivasan%3FtrackingId=HCoYh90Go2XKAWQN8UlaoA%253D%253D/?trackingId=HCoYh90Go2XKAWQN8UlaoA%3D%3D" target="_blank">
              <div className="project-box text-center">
                <img className="project-image" src="images/ai-research.png" alt="Project 4" />
                <h3 className="project-title">AI Research Project</h3>
                <p className="project-description">I was tasked with exploring the vast landscape of AI startups within the field to provide valuable insights for <a href="https://www.linkedin.com/in/saarthi/" target="_blank">Ms. Aarthi's</a> side research project. It was truly an enriching experience to be able to collaborate with her and learn more about the current AI scene.</p>
              </div>
            </a>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="project-box text-center">
                <img className="project-image" src="images/coming-soon.png" alt="Project 4" />
                <h3 className="project-title">Coming Soon</h3>
                <p className="project-description">Follow me to see more content and stay updated with my new projects!</p>
                <br></br>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}
